import React from 'react';
import FileBrowser from '../common/FileBrowser';

const Files = () => {
  return (
    <div className='Files'>
      <FileBrowser />
    </div>
  );
};
export default Files;
