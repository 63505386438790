import React from 'react';
import PatchBrowser from '../common/PatchBrowser';

const AddPatch = () => {
  return (
    <div className='AllPatches'>
      <PatchBrowser />
    </div>
  );
};
export default AddPatch;
